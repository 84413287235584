import React from 'react';
import Redirect from './Components/redirect';

function App() {
  return (
    <div className="App">
      <Redirect />
    </div>
  );
}

export default App;
